<template>
  <div class="user_mobile_list">
    <el-button type="primary" icon="el-icon-arrow-left" class="ml-2 mb-3" plain @click="$router.back()">Back
    </el-button>

    <el-card class="mt-2">
      <div class="d-flex justify-content-between align-items-center">
        <h4>Assigned User List</h4>
        <div v-if="[1, 2].includes(orderDetailInfo.order_status)">
          <el-button v-if="orderDetailInfo.gift_count == orderDetailInfo.number" disabled type="info">The Number Of
            People Is Full</el-button>
          <el-button type="primary" v-else @click="addUser()" plain>
            +Add New</el-button>
        </div>
      </div>
      <el-table :data="orderDetailInfo.gift_log" style="width: 100%" v-loading="loading">
        <el-table-column prop="gift_id" show-overflow-tooltip align="center" min-width="100" label="ID">
        </el-table-column>
        <el-table-column prop="real_name" show-overflow-tooltip align="center" label="User" min-width="180">
        </el-table-column>
        <el-table-column prop="email" show-overflow-tooltip align="center" label="Email" min-width="180">
        </el-table-column>
        <el-table-column prop="add_time" show-overflow-tooltip align="center" label="Time" min-width="180">
        </el-table-column>
        <el-table-column label="Action" align="center" fixed="right" min-width="98">
          <template slot-scope="scope">
            <span><i @click="eventDeleteGiftUsers(scope.row.gift_id)" class="el-icon-delete"></i></span>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog title="Add New" width="40%" :visible.sync="dialogUser">
      <div class="flex">
        <el-select v-model="member" style="width: 100%" filterable placeholder="Please Select"
          :filter-method="filterFriends" clearable popper-class="optionsContent" value-key="user_id"
          @change="chooseMember">
          <el-option v-for="item in userList" :key="item.user_id" :label="item.real_name" :value="item">
            <el-table :data="[item]" style="width: 100%" :show-header="false" tooltip-effect="light">
              <el-table-column prop="date" width="60">
                <template slot-scope="scope">
                  <div class="flex justify-end">
                    <el-avatar icon="el-icon-user-solid" :src="scope.row.avatar" :size="30">
                    </el-avatar>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="real_name" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="email" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="organization_name" show-overflow-tooltip>
              </el-table-column>
            </el-table>
          </el-option>
        </el-select>
      </div>
      <div class="vx-row flex items-center" v-if="tagsList.length > 0">
        <div class="vx-col sm:w-12/12 w-full">
          <el-tag v-for="(tag, i) in tagsList" :key="tag.user_id" closable @close="delTag(i)" type="info"
            class="mr-3 mt-3">
            {{ tag.real_name }}
          </el-tag>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :disabled="tagsList.length == 0" @click="eventGiftUsers">Submit</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        errorImage: require('@/assets/images/public/fail.png'),
        tableData: [],
        orderDetailInfo: {},
        logList: [],
        pageSize: 10,
        total: 0,
        page: 1,
        srcList: [],
        paymentList: [
          'Not Sure',
          'Free',
          'PayPal',
          'Offline[personal]',
          'Offline[group]',
          'Credit card payment',
          'System'
        ],
        checkList: [],
        checkEvent: false,

        dialogUser: false,
        userList: [],
        member: '',
        tagsList: [],

        notes: '',
        dialogNotes: false,
        loading: false
      }
    },

    computed: {},

    created() { },
    mounted() {
      this.getOrderDetail()
      this.getOrderLogList()
    },
    methods: {
      delTag(i) {
        this.tagsList.splice(i, 1)
      },
      openNotes() {
        this.notes = ''
        this.dialogNotes = true
      },
      orderRemark() {
        this.$http
          .orderRemark({
            id: this.$route.query.id,
            note: this.notes
          })
          .then(res => {
            if (res.status == 200) {
              this.dialogNotes = false
              this.notes = ''
              this.getOrderLogList()
            }
          })
      },
      addUser() {
        this.member = ''
        this.tagsList = []
        this.dialogUser = true
        this.searchUserList()
      },
      getCheckList(item) {
        let ajaxType = {
          2: 'getEventProgramList',
          4: 'getMeetingProgramList'
        }
        this.$http[ajaxType[this.$route.query.type]]({
          [this.$route.query.type == 2 ? 'event_id' : 'meeting_id']: this.$route.query.type == 2 ? item.event_id : item.meeting_id,
          order_type: item.order_type,
          order_data: item.order_data
        })
          .then(res => {
            if (res.status == 200) {
              this.checkList = res.data
              this.checkEvent = true
            }
          })
      },
      orderCheck(type) {
        let ajaxCancel = {
          2: 'eventOrderCancel',
          4: 'meetingOrderCancel',
          6: 'cancelProgramOrder',
          8: 'donationOrderCancel'
        }
        let ajaxRefund = {
          2: 'eventRefundApply',
          4: 'meetingRefundApply'
        }
        if (type == 1) {
          this.$router.push({
            path: '/PayMoney',
            query: {
              model_type: 2,
              id: this.orderDetailInfo.id,
              model_id: this.orderDetailInfo.event_id,
              package_type: this.orderDetailInfo.package_type
            }
          })
        } else if (type == 2) {
          this.$confirm('Are you sure you want to cancel?', 'Tips', {
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning'
          })
            .then(() => {
              this.$http[ajaxCancel[this.$route.query.type]]({ id: this.orderDetailInfo.id })
                .then(res => {
                  if (res.status == 200) {
                    this.getOrderLogList()
                    this.getOrderDetail()
                  }
                })
            })
            .catch(() => { })
        } else if (type == 3) {
          this.$confirm('Are you sure you want to apply for a refund?', 'Tips', {
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning'
          })
            .then(() => {
              this.$http[ajaxRefund[this.$route.query.type]]({ id: this.orderDetailInfo.id })
                .then(res => {
                  if (res.status == 200) {
                    this.getOrderLogList()
                    this.getOrderDetail()
                  }
                })
            })
            .catch(() => { })
        }
      },
      searchUserList(val) {
        this.$http
          .searchUserList({
            keyword: val || ''
          })
          .then(res => {
            this.userList = res.data
          })
      },
      chooseMember(item) {
        let idArray = this.tagsList.map(ele => {
          return ele.user_id
        })
        if (
          this.tagsList.length >=
          this.orderDetailInfo.number - this.orderDetailInfo.gift_count
        ) {
          this.$message.warning('The number of donors exceeds!')
          return
        }
        if (idArray.includes(item.user_id)) return
        if (item) {
          this.tagsList.push(item)
          this.member = ''
          this.searchUserList()
        }
      },
      filterFriends(val) {
        clearTimeout(this.timeId)
        this.timeId = setTimeout(() => {
          this.searchUserList(val)
        }, 800)
      },
      eventDeleteGiftUsers(gift_id) {
        let ajaxType = {
          2: 'eventDeleteGiftUsers',
          4: 'meetingDeleteGiftUsers'
        }
        this.$confirm(
          'This operation will delete the record. Are you sure to continue?',
          'Tips',
          {
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        )
          .then(() => {
            this.$http[ajaxType[this.$route.query.type]]({ gift_id }).then(res => {
              if (res.status == 200) {
                this.getOrderLogList()
                this.getOrderDetail()
              }
            })
          })
          .catch(() => { })
      },
      eventGiftUsers() {
        let ajaxType = {
          2: 'eventGiftUsers',
          4: 'meetingGiftUsers'
        }
        let idList = []
        this.tagsList.forEach(item => {
          idList.push(item.user_id)
        })
        console.log(this.$route.query.type);

        this.$http[ajaxType[this.$route.query.type]]({ id: this.$route.query.id, user_id: idList })
          .then(res => {
            if (res.status == 200) {
              this.getOrderDetail()
              this.getOrderLogList()
              this.dialogUser = false
            }
          })
      },
      handleChangePage(num) {
        this.page = num
        this.getOrderLogList()
      },
      getOrderLogList() {
        this.$http
          .getOrderLogList({
            id: this.$route.query.id,
            per_page: this.pageSize,
            page: this.page
          })
          .then(res => {
            if (res.status == 200) {
              this.logList = res.data.data
              this.total = res.data.total
            }
          })
      },
      getOrderDetail() {
        this.loading = true
        let ajaxType = {
          2: 'eventOrderDetail',
          4: 'meetingOrderDetail',
          5: 'vipOrderDetail',
          6: 'programOrderDetail',
          8: 'donationOrderDetail'
        }
        this.tableData = []
        this.srcList = []
        this.$http[ajaxType[this.$route.query.type]]({
          id: this.$route.query.id
        }).then(res => {
          this.loading = false
          if (res.status == 200) {
            this.orderDetailInfo = res.data
            this.tableData.push(res.data)
            this.srcList.push(res.data.offline_certificate)
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  @media (max-width: 600px) {
    .user_mobile_list {
      margin: 0 -15px !important;
    }
    ::v-deep .el-dialog {
      width: 90% !important;
    }

    ::v-deep .el-card__body {
      padding: .625rem !important;
    }

  }

</style>